<template>
  <div>
    <el-dialog
      :title="type === 'add' ? '添加账号' : '编辑账号'"
      top="50px"
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="500px"
    >
      <p class="box-title">基本信息</p>
      <el-form
        ref="formRef"
        :model="formData"
        :rules="formRule"
        label-width="110px"
      >
        <el-form-item label="运营人员姓名" prop="userName">
          <el-input
            v-model.trim="formData.userName"
            maxlength="10"
            placeholder="请输入员工姓名（10位以内字母或汉字）"
            type="text"
          />
        </el-form-item>
        <!-- <el-form-item label="所属角色" prop="roleId">
          <el-select v-model="formData.roleId" filterable placeholder="请选择，若无角色需先创建角色" style="width:100%">
            <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item> -->
        <el-form-item label="手机号" prop="userPhone">
          <el-input
            v-model.trim="formData.userPhone"
            maxlength="11"
            placeholder="请输入手机号"
            type="text"
          />
        </el-form-item>
        <el-form-item
          label="登录账号"
          :prop="type === 'edit' ? '' : 'userAccount'"
        >
          <el-input
            :disabled="type === 'edit'"
            v-model.trim="formData.userAccount"
            maxlength="14"
            placeholder="请输入登录账号（6-14位）"
            type="text"
          />
        </el-form-item>
        <el-form-item
          label="登录密码"
          :prop="type === 'edit' ? '' : 'userPassword'"
          v-if="type === 'add'"
        >
          <el-input
            v-model.trim="formData.userPassword"
            maxlength="14"
            placeholder="请输入登录密码（6-14位）"
            type="text"
          />
        </el-form-item>
      </el-form>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <p class="box-title">选择部门</p>
        <div class="out_cle">
          <el-checkbox v-model="formData.isOutUser">标记外部人员</el-checkbox>
        </div>
      </div>
      <div class="select-content">
        <div
          style="line-height: 32px; margin-bottom: 10px"
          v-for="item in localTerminalList"
          :key="item.marketerTerminalCode"
        >
          <el-checkbox style="margin-right: 10px" v-model="item.isSelect">{{
            item.marketerTerminalName
          }}</el-checkbox>
          <el-select
            size="small"
            v-show="item.isSelect"
            v-model="item.roleId"
            placeholder="请选择角色"
          >
            <el-option
              v-for="item in item.roleList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="visible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="confirmBtnLoading"
          @click.native="confirm"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="操作提示"
      :visible.sync="dialogSureVisible"
      :close-on-click-modal="false"
      width="400px"
    >
      <div
        style="
          font-size: 16px;
          color: #666666;
          text-align: center;
          height: 100px;
          line-height: 100px;
        "
      >
        保存后将退出登录，是否确认提交？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogSureVisible = false">取消</el-button>
        <el-button
          type="primary"
          :loading="sureHandleBtnLoading"
          @click.native="sureHandleSuccess"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { formValidateMobile } from "@/utils/form-validate";

const originFormData = {
  userName: "",
  userPhone: "",
  userAccount: "",
  userPassword: "",
  isOutUser:false,
};
export default {
  name: "AccountDia",
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    const limitValidator = (rule, value, callback) => {
      const chinaReg = /[\u4e00-\u9fa5]/;
      if (chinaReg.test(value))
        callback(new Error("请输入字母、数字或特殊字符"));
      if (value.length >= 6 && value.length <= 14) callback();
      callback(new Error("请输入6-14位"));
    };
    const userNameValidate = (rule, value, callback) => {
      const reg = /^[a-zA-Z\u4e00-\u9fa5]*$/;
      if (reg.test(value)) callback();
      callback(new Error("请输入字母或汉字"));
    };
    return {
      visible: false,
      formData: originFormData,
      isOut: "",
      formRule: {
        userName: [
          { required: true, message: "请输入姓名", trigger: "change" },
          { validator: userNameValidate, trigger: "change" },
        ],
        // roleId: [{ required: true, message: '请选择角色', trigger: 'change' }],
        userPhone: [
          { required: true, message: "请输入手机号", trigger: "change" },
          { validator: formValidateMobile, trigger: "change" },
        ],
        userAccount: [
          { required: true, message: "请输入账号", trigger: "change" },
          { validator: limitValidator, trigger: "change" },
        ],
        userPassword: [
          { required: true, message: "请输入密码", trigger: "change" },
          { validator: limitValidator, trigger: "change" },
        ],
      },
      roleList: [],
      section: "",
      localTerminalList: [],
      dialogSureVisible: false,
      sureHandleBtnLoading: false,
      confirmBtnLoading: false,
      userInfo: {},
      id: "",
    };
  },
  computed: {
    terminalList() {
      return this.$store.state.tagsView.terminalList;
    },
  },
  watch: {
    terminalList: {
      handler(newVal) {
        this.localTerminalList = newVal.map((item) => ({
          ...item,
          isSelect: false,
          roleId: "",
        }));
        this.getRoleList();
      },
      immediate: true, // 立即执行一次
      deep: true, // 深度监听
    },
  },
  methods: {
    open(id) {
      if (id) {
        this.id = id;
        this.getUserSelectById(id);
      }
      this.formData = JSON.parse(JSON.stringify(originFormData));
      this.$refs["formRef"]?.clearValidate();
      this.$nextTick(() => {
        this.visible = true;
      });
    },
    getRoleList() {
      _api.marketerRoleSelectList().then((res) => {
        this.localTerminalList.forEach((item) => {
          item.roleList = res.data.filter(
            (ele) => ele.marketerTerminalCode === item.marketerTerminalCode
          );
        });
      });
    },
    close() {
      this.visible = false;
    },
    confirm() {
      this.$refs["formRef"].validate((vaild) => {
        if (vaild) {
          let shopSign = [];
          let companyRoleId = "";
          let merchantRoleId = "";
          let flag = false;
          let isSelect = false;
          this.localTerminalList.forEach((item) => {
            if (item.isSelect) {
              isSelect = true;
              shopSign.push(item.marketerTerminalCode);
              if (!item.roleId) {
                flag = true;
              } else {
                if (item.marketerTerminalCode === "Store_Market") {
                  companyRoleId = item.roleId;
                }
                if (item.marketerTerminalCode === "Merchant_Market") {
                  merchantRoleId = item.roleId;
                }
              }
            }
          });
          if (!isSelect) {
            this.$message.error("请选择部门！");
            return;
          }
          if (flag) {
            this.$message.error("请选择角色！");
            return;
          }
          let params = {
            ...this.formData,
            shopSign,
            companyRoleId,
            merchantRoleId,
          };
          if (this.type === "add") {
            this.confirmBtnLoading = true;
            _api
              .marketerUserSave(params)
              .then(() => {
                this.$emit("confirm");
                this.$message.success("操作成功！");
                this.close();
              })
              .finally(() => {
                this.confirmBtnLoading = false;
              });
          } else {
            let {
              userName,
              userPhone,
              shopSign,
              isOutUser,
              companyRoleId,
              merchantRoleId,
            } = this.userInfo;
            if (params) {
              if (
                params.isOutUser !== isOutUser ||
                params.userName !== userName ||
                params.userPhone !== userPhone ||
                JSON.stringify(params.shopSign) !== JSON.stringify(shopSign) ||
                params.companyRoleId !== companyRoleId ||
                params.merchantRoleId !== merchantRoleId
              ) {
                this.dialogSureVisible = true;
              } else {
                this.$message.error("未修改任何内容，无需提交");
              }
            }
          }
        }
      });
    },
    sureHandleSuccess() {
      let shopSign = [];
      let companyRoleId = "";
      let merchantRoleId = "";
      this.localTerminalList.forEach((item) => {
        if (item.isSelect) {
          shopSign.push(item.marketerTerminalCode);
          if (item.marketerTerminalCode === "Store_Market" && item.roleId) {
            companyRoleId = item.roleId;
          }
          if (item.marketerTerminalCode === "Merchant_Market" && item.roleId) {
            merchantRoleId = item.roleId;
          }
        }
      });
      this.sureHandleBtnLoading = true;
      let params = {
        id: this.id,
        userName: this.formData.userName,
        userPhone: this.formData.userPhone,
        isOutUser: this.formData.isOutUser,
        shopSign,
        companyRoleId,
        merchantRoleId,
      };
      _api
        .marketerUserSave(params)
        .then(() => {
          this.dialogSureVisible = false;
          this.$emit("confirm");
          this.$message.success("操作成功！");
          this.close();
        })
        .finally(() => {
          this.sureHandleBtnLoading = false;
        });
    },
    // 编辑账号，获取账号详情
    getUserSelectById(id) {
      _api.getUserSelectById({ id }).then((res) => {
        if (res.code === 1 && res.data) {
          this.userInfo = res.data;
          this.userInfo.merchantRoleId = res.data.merchantRoleId || "";
          this.userInfo.companyRoleId = res.data.companyRoleId || "";
          this.formData.userPhone = res.data.userPhone;
          this.formData.userName = res.data.userName;
          this.formData.isOutUser = res.data.isOutUser;
          this.formData.userAccount = res.data.userAccount;
          console.log(this.formData);
          this.localTerminalList.forEach((item) => {
            if (res.data.shopSign && res.data.shopSign.length) {
              item.isSelect = false;
              if (res.data.shopSign.includes(item.marketerTerminalCode)) {
                item.isSelect = true;
                item.roleId =
                  item.marketerTerminalCode == "Store_Market"
                    ? res.data.companyRoleId
                    : item.marketerTerminalCode == "Merchant_Market"
                    ? res.data.merchantRoleId
                    : "";
              }
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss" ref="stylesheet/scss">
.box-title {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: " ";
    width: 4px;
    height: 16px;
    background: #0981ff;
    border-radius: 2px;
  }
}
.select-content {
  padding: 0 20px;
}
.out_cle {
  margin-left: 50px;
  ::v-deep .el-checkbox__inner {
    border-radius: 50%;
  }
  ::v-deep .el-checkbox__label {
    font-size: 14px;
    font-weight: 400;
    color: #596480;
    padding-left: 3px;
  }
  ::v-deep .is-checked .el-checkbox__inner {
    border-radius: 50%;
    border: 1px solid #ff687b;
    background-color: transparent;
  }
  /deep/.el-checkbox__inner:hover {
    border-color: #E4ECFD !important;
   }
   ::v-deep .el-checkbox__input .is-focus .el-checkbox__inner{
    border-color: #E4ECFD !important;
   }
  ::v-deep .is-checked .el-checkbox__inner::after {
    width: 8px;
    height: 8px;
    content: "";
    border: none;
    background-color: #ff687b;
    left: 2.2px;
    top: 2px;
    border-radius: 50%;
    transform: none;
  }
  /deep/.el-checkbox .el-checkbox__label {
    margin-left: 7px;
    color: #ff687b; /* 修改字体颜色 */
  }
}
</style>